/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactElement } from 'react';
import './Table.css';

interface Props {
  id: string,
  headers: string[];
  children: ReactElement | ReactElement[];
  dataTestId?: string;
}

export default function Table({
  id,
  headers,
  children,
  dataTestId,
}: Props) {
  return (
    <table id={id} className="table" data-testid={dataTestId}>
      <thead>
        <tr>
          {headers.map((header: string) => (
            <th key={`${id}-th-${header}`}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </table>
  );
}
