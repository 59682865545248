/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { RootState } from '../store';
import { http } from '../../api/http';
import { getApiGatewayBaseUrl } from '../../services/environment.service';
import { ConsultantCsvRecord } from '../../types/ConsultantCsvRecord';

const addConsultantUrl = getApiGatewayBaseUrl() + (process.env.REACT_APP_ADD_CONSULTANTS as string);

export interface ConsultantState {
  insertedRecords: ConsultantCsvRecord[];
  updatedRecords: ConsultantCsvRecord[];
  loading: boolean;
  error: string | null;
}

const initialState: ConsultantState = {
  insertedRecords: [],
  updatedRecords: [],
  loading: false,
  error: null,
};

export const addConsultants = createAsyncThunk(
  'consultant/addConsultants',
  async (consultants: ConsultantCsvRecord[]) => {
    const config: InternalAxiosRequestConfig = {
      headers: new AxiosHeaders({
        'Content-Type': 'application/json',
      }),
    };

    const httpClient = http.initHttp(config);
    const responseData = await httpClient.post(
      addConsultantUrl,
      { consultants },
    ).then((response: AxiosResponse) => response.data);

    return responseData;
  },
);

export const consultantSlice = createSlice({
  name: 'consultant',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addConsultants.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addConsultants.fulfilled, (state, action) => {
      state.loading = false;
      state.insertedRecords = action.payload.data.inserted_records;
      state.updatedRecords = action.payload.data.updated_records;
    });
    builder.addCase(addConsultants.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Failed to add consultants';
    });
  },
});

export const consultantSelector = (state: RootState) => state.consultantReducer;
export default consultantSlice.reducer;
