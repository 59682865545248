/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from 'react';
import { AccrualDetails } from '../../../types/AccrualDetails';
import Textfield from '../../Textfield/Textfield';
import Dropdown from '../../Dropdown/Dropdown';
import './AccrualDetailsRow.css';
import AppButton, { ButtonStyles } from '../../AppButton/AppButton';
import { useAppSelector } from '../../../redux/hooks';

type ValidationModel = {
  isValid: boolean;
  errorMessage: string;
};

const isHoursValid = (hours: string): ValidationModel => {
  const val = Number.parseInt(hours, 10);

  if (hours === '') return { isValid: true, errorMessage: '' };
  if (Number.isNaN(val)) return { isValid: false, errorMessage: 'Hours must be numerical only' };
  if (val < 0) return { isValid: false, errorMessage: 'Hours cannot be negative' };
  if (val === 0) return { isValid: false, errorMessage: 'Hours entered must be greater than 0 ' };

  return { isValid: true, errorMessage: '' };
};

type AccrualDetailsRowProps = {
  isDisabled?: boolean;
  accrualDetailsRow: AccrualDetails;
  updateAccrualDetailsRow: (
    id: string,
    accrual_details_id: string,
    project_name: string,
    task_name: string,
    project_task_id: string,
    hours_qty: string,
    status_id: string,
  ) => void
  deleteAccrualDetailsRow: (id: string, accrual_details_id: string) => void;
};

export default function AccrualDetailsRow({
  isDisabled = false,
  accrualDetailsRow,
  updateAccrualDetailsRow,
  deleteAccrualDetailsRow,
}: AccrualDetailsRowProps) {
  const [rowState, setRowState] = React.useState<{
    isCodeError: boolean;
    taskCodeErrorLabel: string;
    hoursErrorLabel: string;
  }>();
  const [taskOptions, setTaskOptions] = useState<string[]>([]);
  const { projectTaskOptions } = useAppSelector((state) => state.accrualReducer);
  const projectOptions: string[] = Array.from(new Set(
    projectTaskOptions.map((item) => item.project_name),
  ));

  const onDeleteButtonHandler = () => {
    deleteAccrualDetailsRow(accrualDetailsRow.id, accrualDetailsRow.accrual_details_id);
  };

  const getTaskOptions = (project_name: string) => {
    const filteredTaskOptions: string[] = projectTaskOptions.filter(
      (item) => item.project_name === project_name,
    )
      .map((item) => item.task_name);
    setTaskOptions(filteredTaskOptions);
  };

  useEffect(() => {
    getTaskOptions(accrualDetailsRow.project_name);
  }, [accrualDetailsRow.project_name]);

  useEffect(() => {
    setRowState({
      isCodeError: false,
      taskCodeErrorLabel: '',
      hoursErrorLabel: '',
    });
  }, []);

  const getProjectTaskID = (project_name: string, task_name: string) => {
    const projectTaskObject = projectTaskOptions.find(
      (item) => item.project_name === project_name && item.task_name === task_name,
    );
    return projectTaskObject ? projectTaskObject.project_task_id : '';
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLElement>) => {
    const parseProjectField = (target: HTMLSelectElement) => {
      updateAccrualDetailsRow(
        accrualDetailsRow.id,
        accrualDetailsRow.accrual_details_id,
        target.value,
        '',
        accrualDetailsRow.project_task_id,
        accrualDetailsRow.hours_qty,
        accrualDetailsRow.status_id,
      );
      const codeIsValid = target.value !== '';
      setRowState({
        isCodeError: !codeIsValid,
        taskCodeErrorLabel: (!codeIsValid) ? 'Project code is required' : '',
        hoursErrorLabel: rowState?.hoursErrorLabel ?? '',
      });
    };

    const parseTaskField = (target: HTMLSelectElement) => {
      const projectTaskID = getProjectTaskID(accrualDetailsRow.project_name, target.value);
      updateAccrualDetailsRow(
        accrualDetailsRow.id,
        accrualDetailsRow.accrual_details_id,
        accrualDetailsRow.project_name,
        target.value,
        projectTaskID,
        accrualDetailsRow.hours_qty,
        accrualDetailsRow.status_id,
      );
      const codeIsValid = target.value !== '';
      setRowState({
        isCodeError: !codeIsValid,
        taskCodeErrorLabel: (!codeIsValid) ? 'Task code is required' : '',
        hoursErrorLabel: rowState?.hoursErrorLabel ?? '',
      });
    };

    const parseHoursField = (target: HTMLInputElement) => {
      const hours_qty = target.value.replace(/\D/g, '');
      if (hours_qty.length > 3) { return; }
      updateAccrualDetailsRow(
        accrualDetailsRow.id,
        accrualDetailsRow.accrual_details_id,
        accrualDetailsRow.project_name,
        accrualDetailsRow.task_name,
        accrualDetailsRow.project_task_id,
        hours_qty,
        accrualDetailsRow.status_id,
      );
      setRowState({
        isCodeError: rowState?.isCodeError ?? false,
        taskCodeErrorLabel: rowState?.taskCodeErrorLabel ?? '',
        hoursErrorLabel: isHoursValid(hours_qty).errorMessage,
      });
    };

    switch (e.target.id) {
      case 'hours':
        parseHoursField(e.target as HTMLInputElement);
        break;
      case 'projectCode':
        parseProjectField(e.target as HTMLSelectElement);
        break;
      case 'taskCode':
        parseTaskField(e.target as HTMLSelectElement);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Dropdown
        id="projectCode"
        label="Project Code"
        items={projectOptions}
        selectedItem={accrualDetailsRow.project_name}
        onChangeHandler={onChangeHandler}
        isError={rowState?.isCodeError}
        errorMessage={rowState?.taskCodeErrorLabel}
        dataTestId="select-project-code-test-id"
        width="555px"
        isDisabled={isDisabled}
      />
      <Dropdown
        id="taskCode"
        label="Task Code"
        items={taskOptions}
        selectedItem={accrualDetailsRow.task_name}
        onChangeHandler={onChangeHandler}
        isError={rowState?.isCodeError}
        errorMessage={rowState?.taskCodeErrorLabel}
        dataTestId="select-task-code-test-id"
        width="555px"
        isDisabled={isDisabled || (taskOptions.length === 0 && accrualDetailsRow.task_name === '')}
      />
      <Textfield
        id="hours"
        label="Hours Qty"
        value={(accrualDetailsRow.hours_qty)?.replace(/\.\d+/, '')}
        onChangeHandler={onChangeHandler}
        isError={rowState?.hoursErrorLabel !== '' && accrualDetailsRow.accrual_details_id === ''}
        errorMessage={rowState?.hoursErrorLabel}
        dataTestId="textfield-hours-qty-test-id"
        disabled={isDisabled}
      />
      <span id="removeIcon">
        <AppButton buttonStyle={ButtonStyles.TERTIARY} onClick={onDeleteButtonHandler} dataTestId="btn-project-remove-test-id" disabled={isDisabled}>
          <img src="images/remove.png" alt="remove icon" height="32" width="32" />
        </AppButton>
      </span>
    </>
  );
}
