/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { AxiosHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { EngagementCsvRecord } from '../../types/EngagementCsvRecord';
import { RootState } from '../store';
import { http } from '../../api/http';
import { getApiGatewayBaseUrl } from '../../services/environment.service';

const uploadEngagementsUrl = getApiGatewayBaseUrl() + (process.env.REACT_APP_UPLOAD_ENGAGEMENTS as string);

export interface EngagementState {
  engagements: EngagementCsvRecord[];
  loading: boolean;
  error: string | null;
}

const initialState: EngagementState = {
  engagements: [],
  loading: false,
  error: null,
};

export const addEngagements = createAsyncThunk(
  'engagement/addEngagements',
  async (engagements: EngagementCsvRecord[], { rejectWithValue }) => {
    const config: InternalAxiosRequestConfig = {
      headers: new AxiosHeaders({
        'Content-Type': 'application/json',
      }),
    };

    const httpClient = http.initHttp(config);
    try {
      const responseData = await httpClient.post(
        uploadEngagementsUrl,
        { engagements },
      ).then((response: AxiosResponse) => response.data);

      return responseData;
    } catch (error) {
      return rejectWithValue(error || 'Unknown error');
    }
  },
);

export const consultantEngagementSlice = createSlice({
  name: 'engagement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addEngagements.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addEngagements.fulfilled, (state, action) => {
      state.loading = false;
      state.engagements = action.payload.data;
    });
    builder.addCase(addEngagements.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Failed to upload engagement data';
    });
  },
});

export const engagementSelector = (state: RootState) => state.consultantEngagementReducer;
export default consultantEngagementSlice.reducer;
