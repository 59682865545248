import React from 'react';
import { Link } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import './Header.css';

function Header() {
  const { accounts, instance } = useMsal();

  return (
    <div className="Header">
      <img className="Header-logo" src="images/daugherty-logo-white.png" alt="daugherty-logo" data-testid="header-img-test-id" />
      <div className="Header-title" data-testid="header-title-test-id"><Link to="/">Timecast</Link></div>
      <div className="Header-auth">
        <AuthenticatedTemplate>
          {accounts[0] ? `${accounts[0].name || accounts[0].username}` : 'Signed in'}
          {' | '}
          <button type="button" onClick={() => instance.logoutRedirect()}>Sign out</button>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <button type="button" onClick={() => instance.loginRedirect()}>Sign in</button>
        </UnauthenticatedTemplate>
      </div>
    </div>
  );
}

export default Header;
