import axios, {
  AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig,
} from 'axios';
import { aquireToken } from './apiAccessToken';

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

// const headers: Readonly<Record<string, string | boolean>> = {
//   Accept: 'application/json',
//   'Content-Type': 'application/json; charset=utf-8',
//   'Access-Control-Allow-Credentials': true,
//   'X-Requested-With': 'XMLHttpRequest',
// };

const injectToken = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const theConfig = config; // gets past Assignment to property of function parameter 'config' - no-param-reassign
  try {
    const token = await aquireToken();
    if (token != null) {
      theConfig.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (error) {
    return config;
  }
};

class Http {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp(conf?: InternalAxiosRequestConfig) {
    const http = axios.create(conf);

    http.interceptors.request.use((injectToken), (error) => Promise.reject(error));

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        return this.handleError(response);
      },
    );

    this.instance = http;
    return http;
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  // ToDo Handle global app errors
  // We can handle generic app errors depending on the status code
  private handleError(error: { status: any; }) {
    const { status } = error;

    switch (status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCode.Forbidden: {
        // Handle Forbidden
        break;
      }
      case StatusCode.Unauthorized: {
        // Handle Unauthorized
        break;
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
      default: {
        // generic handler
        break;
      }
    }

    return Promise.reject(error);
  }
}

export const http = new Http();
