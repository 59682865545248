import { PublicClientApplication } from '@azure/msal-browser';
import { getClientId, getTenantId } from '../services/environment.service';

export const msalConfig = {
  auth: {
    clientId: getClientId(),
    authority: `https://login.microsoftonline.com/${getTenantId()}`,
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ['https://graph.microsoft.com/.default'],
  // scopes: [`${msalConfig.auth.clientId}/.default`], // original
};
