export const getApiGatewayBaseUrl = (): string => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production':
      return process.env.REACT_APP_PROD_BASE_URL as string;
    case 'development':
      return process.env.REACT_APP_DEV_BASE_URL as string;
    default:
      return process.env.REACT_APP_LOCAL_BASE_URL as string;
  }
};
export const getClientId = (): string => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production':
      return process.env.REACT_APP_PROD_SSO_CLIENT_ID as string;
    case 'development':
      return process.env.REACT_APP_DEV_SSO_CLIENT_ID as string;
    default:
      return process.env.REACT_APP_LOCAL_SSO_CLIENT_ID as string;
  }
};
export const getTenantId = (): string => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production':
      return process.env.REACT_APP_PROD_SSO_TENANT_ID as string;
    case 'development':
      return process.env.REACT_APP_DEV_SSO_TENANT_ID as string;
    default:
      return process.env.REACT_APP_LOCAL_SSO_TENANT_ID as string;
  }
};
