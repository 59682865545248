import './HoursExceeded.css';
import React from 'react';

function HoursExceeded() {
  const paragraph1 = 'The total hours introduced exceeds the limit for this month (see \'Hours available\' reference field on the screen).';
  const paragraph2 = 'Please make sure this is correct. You can cancel to verify the hours introduced or continue to save the hours entered by pressing \'OK\'.';
  return (
    <div>
      <p>{paragraph1}</p>
      <p>{paragraph2}</p>
      <p>
        Please reach out to
        <a href="mailto:silke.heinrici@daugherty.com">Silke Heinrici</a>
        for questions on this topic.
      </p>
    </div>
  );
}

export default HoursExceeded;
