/* eslint-disable class-methods-use-this */
import { IMsalContext, useMsal } from '@azure/msal-react';

export default class AuthenticationService {
  iMsalContext: IMsalContext;

  constructor() {
    this.iMsalContext = useMsal();
  }

  getLoggedUserEmail(): string {
    let email = '';
    try {
      email = this.iMsalContext.accounts[0].username;
    } catch (e) {
      email = '';
    }
    return email;
  }
}
