import { UserRole } from '../types/UserRole';
import { AppRoutes } from '../routing/appRoutes';
import { Consultant } from '../types/Consultant';

export function getCancelEditAccuralFormRoute(consultant: Consultant): string {
  return consultant.role_name === UserRole.OPERATIONS
    ? AppRoutes.ENGAGEMNET_ACCURALS : AppRoutes.ENGAGEMNETS;
}

export function getEngagementDetailsRoute(consultant: Consultant): string {
  return consultant.role_name === UserRole.OPERATIONS
    ? AppRoutes.ENGAGEMNET_ACCURALS : AppRoutes.ACCURAL_EDIT_FORM;
}

export function getSaveEditAccuralFormRoute(consultant: Consultant): string {
  return consultant.role_name === UserRole.OPERATIONS
    ? AppRoutes.ENGAGEMNET_ACCURALS : AppRoutes.PERIODS;
}
