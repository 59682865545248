import React, { useEffect } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import axios, { AxiosHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import Modal from 'react-modal'; // todo: ak - encapsulate it with own modal class
import Table from '../Table/Table';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { dataBankSelector, updateConsultant } from '../../redux/slices/dataBankSlice';
import { Engagement } from '../../types/Engagement';
import { engagementSelector, getEngagementDetails } from '../../redux/slices/engagementSlice';
import HelperText from '../HelperText/HelperText';
import { getEngagementDetailsRoute } from '../../services/routing.service';
import { getApiGatewayBaseUrl } from '../../services/environment.service';
import { http } from '../../api/http';
import AppButton, { ButtonStyles } from '../AppButton/AppButton';
import { selectIsLoggedUserConsultant } from '../../redux/slices/dataBankSelector';

const exportEngagementAccrualsUrl = getApiGatewayBaseUrl() + (process.env.REACT_APP_EXPORT_ENGAGEMENT_ACCRUALS as string);

const modalStyle: Modal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 'auto',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
  },
  content: {
    bottom: 'auto',
    position: 'absolute',
    left: '0',
    right: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '245px',
    width: '550px',
    border: '1px solid #ccc',
    boxShadow: '2px 2px 3px #ccc',
    background: '#fff',
    overflow: 'visible',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
  },
};

function Loader() {
  return (
    <div className="loader">
      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
      />
    </div>
  );
}

export default function Engagements() {
  const dispatch = useAppDispatch();
  const { consultant } = useAppSelector(dataBankSelector);
  const { engagements, error, loading } = useAppSelector(engagementSelector);
  const isLoggedUserConsultant: boolean = useAppSelector(selectIsLoggedUserConsultant);
  const [isExportModalOpen, setIsExportModalOpen] = React.useState(false);
  const [exporting, setExporting] = React.useState(false);

  const payload = {
    consultant_id: consultant?.consultant_id as string,
    period_id: consultant?.period_id as string,
    role_name: consultant?.role_name as string,
  };

  useEffect(() => {
    dispatch(getEngagementDetails(payload));
  }, []);

  function handleClick(
    engagement_id: string,
    consultant_engagement_period_id: string,
    engagement_name: string,
    hm_first_name: string,
    hm_last_name: string,
  ): void {
    dispatch(updateConsultant({
      engagement_id, consultant_engagement_period_id, engagement_name, hm_first_name, hm_last_name,
    }));
  }

  const performDataExport = async () => {
    try {
      setExporting(true);

      const params = new URLSearchParams();
      params.append('period_id', payload.period_id);

      const config: InternalAxiosRequestConfig = {
        headers: new AxiosHeaders({
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        }),
      };

      const httpClient = http.initHttp(config);

      const responseData = await httpClient.post(
        exportEngagementAccrualsUrl,
        params,
      ).then((response: AxiosResponse) => (response.data));

      const { url, filename } = responseData;

      const response = await axios.get(url, {
        responseType: 'blob',
      });

      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setExporting(false);
      // eslint-disable-next-line
      console.error(err);
    } finally {
      setExporting(false);

      setIsExportModalOpen(false);
    }
  };

  const handleExportDataClick = (): void => {
    setIsExportModalOpen(true);
  };

  function handleEngagements() {
    if (engagements?.length > 0) {
      return (
        <>
          <div className="title-bar">
            <span className="title-bar-title">{`${consultant.period_name} Engagements`}</span>
            <div className="right-side-buttons" style={{ display: 'flex', gap: '10px' }}>
              <AppButton
                buttonStyle={isLoggedUserConsultant ? ButtonStyles.DISABLED : ButtonStyles.PRIMARY}
                dataTestId="periods-open-period-test-id"
                disabled={isLoggedUserConsultant}
                onClick={handleExportDataClick}
              >
                {exporting ? <>Export in Progress..</> : <>Export Engagement Data</>}
              </AppButton>

              {/* Modal for Export Data */}
              <Modal
                isOpen={isExportModalOpen}
                contentLabel="Operation confirmation"
                style={modalStyle}
              >
                <div className="modal-container">
                  <div className="header-row">
                    <div className="error-title">
                      Confirm operation
                    </div>
                    <div className="header-button">
                      <AppButton
                        buttonStyle={ButtonStyles.TERTIARY}
                        onClick={() => { setIsExportModalOpen(false); }}
                      >
                        <img src="images/close.png" alt="copy icon" height="12" width="12" />
                      </AppButton>
                    </div>
                  </div>
                  {exporting ? Loader() : (
                    <div className="modal-text">
                      This will package and download the Engagement data.  This may take some time and cannot be interrupted.
                      <div>
                        <br />
                        Are you sure?
                      </div>
                    </div>
                  )}
                  <div className="bottom-row">
                    <div className="right-side-buttons">
                      <div className="padding-right">
                        <AppButton
                          buttonStyle={ButtonStyles.PRIMARY}
                          onClick={() => { performDataExport(); }}
                          dataTestId="export-data-modal-btn-test-id"
                          disabled={exporting}
                        >
                          Yes
                        </AppButton>
                      </div>
                      <AppButton
                        buttonStyle={ButtonStyles.SECONDARY}
                        onClick={() => { setIsExportModalOpen(false); }}
                        dataTestId="cancel-export-data-modal-btn-test-id"
                        disabled={exporting}
                      >
                        No
                      </AppButton>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
          <Table
            id="engagements-table"
            headers={['Engagement Name', 'Hiring Manager', consultant.role_name === 'operations' ? 'Accruals Progress' : 'Hours Qty']}
            dataTestId="engagements-table-test-id"
          >
            {engagements.map((engagement: Engagement) => (
              <tr key={uuid()}>
                <td>
                  <Link
                    to={getEngagementDetailsRoute(consultant)}
                    onClick={() => handleClick(
                      engagement.engagement_id,
                      engagement.consultant_engagement_period_id,
                      engagement.engagement_name,
                      engagement.hm_first_name,
                      engagement.hm_last_name,
                    )}
                  >
                    {engagement.engagement_name}
                  </Link>
                </td>
                <td>{`${engagement.hm_last_name}, ${engagement.hm_first_name}`}</td>
                <td>
                  {consultant.role_name === 'operations'
                    ? `${engagement.consultants_with_booked_hours} / ${engagement.total_consultants}`
                    : `${engagement.total_hours === null ? 'Not entered' : Math.trunc(Number(engagement.total_hours))}`}
                </td>
              </tr>
            ))}
          </Table>
        </>
      );
    }
    return <HelperText loading={loading} error={error} content="engagements" />;
  }

  return (
    <div className="container">
      {handleEngagements()}
    </div>
  );
}
