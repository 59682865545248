import React, { useMemo, useState } from 'react';
import { ToastProps } from './Toast';
import ToastList from './ToastList';
import ToastContext from './ToastContext';

const DEFAULT_TOAST_DISPLAY_TIME_IN_MS = 2000;
function ToastProvider({ children }:any) {
  const [toasts, setToasts] = useState<Omit<ToastProps, 'onDismiss'>[]>([]);
  const handleToastItemRemoval = (id: string | number) => {
    setToasts((previous) => previous.filter((toast) => toast.id !== id));
  };
  const showToast = (message: string, type: 'success' | 'error', removeAfter = DEFAULT_TOAST_DISPLAY_TIME_IN_MS) => {
    if (!type) {
      throw new Error('Must specify provide a toast type');
    }
    const newToast = {
      type,
      message,
      id: Date.now(),
    };

    setToasts((previous) => [...previous, newToast]);
    if (removeAfter) {
      setTimeout(() => {
        handleToastItemRemoval(newToast.id);
      }, removeAfter);
    }
  };
  const contextValue = useMemo(() => ({ toasts, setToasts, showToast }), [toasts]);
  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <ToastList data={toasts} position="top-right" onRemoveListItem={handleToastItemRemoval} />
    </ToastContext.Provider>
  );
}
export default ToastProvider;
