/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { AxiosHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Consultant } from '../../types/Consultant';
import { Engagement } from '../../types/Engagement';
import { Period } from '../../types/Period';
import { Status } from '../../types/Status';
import { Statuses } from '../../types/Statuses';
import { getApiGatewayBaseUrl } from '../../services/environment.service';
import { http } from '../../api/http';

const baseUrl = getApiGatewayBaseUrl();
const getConsultantByEmailUrl = baseUrl + (process.env.REACT_APP_GET_CONSULTANT_BY_EMAIL as string);
const closePeriodUrl = baseUrl + (process.env.REACT_APP_CLOSE_PERIOD as string);
const createPeriodUrl = baseUrl + (process.env.REACT_APP_CREATE_PERIOD as string);

export interface DataBankState {
  consultant: Consultant,
  engagements: Engagement[],
  periods: Period[],
  statuses: Status[],
  loading: boolean,
  error: string | undefined,
}

const initialState: DataBankState = {
  consultant: {} as Consultant,
  engagements: [],
  periods: [],
  statuses: [],
  loading: false,
  error: undefined,
};

export const getConsultantByEmail = createAsyncThunk(
  'databank/getConsultantByEmail',
  async (email_address: string) => {
    const params = new URLSearchParams();
    params.append('email_address', email_address);

    const config: InternalAxiosRequestConfig = {
      headers: new AxiosHeaders({
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      }),
    };

    const httpClient = http.initHttp(config);
    const responseData = await httpClient.post(
      getConsultantByEmailUrl,
      params,
    ).then((response: AxiosResponse) => (
      response.data));
    return responseData;
  },
);

export const closePeriod = createAsyncThunk(
  'databank/closePeriod',
  async (periodId: string) => {
    const params = new URLSearchParams();
    params.append('period_id', periodId);

    const config: InternalAxiosRequestConfig = {
      headers: new AxiosHeaders({
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      }),
    };

    const httpClient = http.initHttp(config);
    const responseData = await httpClient.post(
      closePeriodUrl,
      params,
    ).then((response: AxiosResponse) => (
      response.data));
    return responseData;
  },
);

export const createPeriod = createAsyncThunk(
  'databank/createPeriod',
  async (args: { startDate: Date, deadline: Date, hoursAvailable: number }) => {
    const params = {
      start_date: args.startDate.toISOString().substring(0, 10),
      deadline: args.deadline.toISOString().substring(0, 10),
      hours_available: args.hoursAvailable,
    };

    const config: InternalAxiosRequestConfig = {
      headers: new AxiosHeaders(),
    };

    const httpClient = http.initHttp(config);
    const responseData = await httpClient.post(
      createPeriodUrl,
      params,
    ).then((response: AxiosResponse) => (
      response.data));
    return responseData;
  },
);

export const dataBankSlice = createSlice({
  name: 'dataBank',
  initialState,
  reducers: {
    updateConsultant: (state, action: PayloadAction<Consultant>) => {
      state.consultant = { ...state.consultant, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConsultantByEmail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getConsultantByEmail.fulfilled, (state, action) => {
      const consultantObject: Consultant = action.payload[0][1][0];
      const engagementsArray: Engagement[] = action.payload[1][1];
      const periodsArray: Period[] = action.payload[2][1];
      const statusesArray: Status[] = action.payload[3][1];
      state.loading = false;
      state.consultant = consultantObject;
      state.engagements = engagementsArray;
      state.periods = periodsArray;
      state.statuses = statusesArray;
    });
    builder.addCase(getConsultantByEmail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(closePeriod.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(closePeriod.fulfilled, (state, action) => {
      state.loading = false;
      const periodId: string = action.meta.arg;
      const periodIdToClose: number = state.periods.findIndex((e: Period) => e.period_id
        === periodId);
      state.periods[periodIdToClose] = {
        ...state.periods[periodIdToClose],
        status_label: Statuses.CLOSED,
      };
    });
    builder.addCase(closePeriod.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(createPeriod.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPeriod.fulfilled, (state, action) => {
      state.loading = false;
      state.periods.push(action.payload);
    });
    builder.addCase(createPeriod.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { updateConsultant } = dataBankSlice.actions;
export const dataBankSelector = (state: RootState) => state.dataBankReducer;
export default dataBankSlice.reducer;
