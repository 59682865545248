/* eslint-disable import/no-cycle */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import accrualEditFormReducer from './slices/accrualEditFormSlice';
import accrualReducer from './slices/accrualSlice';
import dataBankReducer from './slices/dataBankSlice';
import engagementReducer from './slices/engagementSlice';
import engagementAccrualReducer from './slices/engagementAccrualSlice';
import consultantReducer from './slices/consultantSlice';
import consultantEngagementReducer from './slices/consultantEngagementSlice';

const rootReducer = combineReducers({
  accrualReducer,
  accrualEditFormReducer,
  dataBankReducer,
  engagementReducer,
  engagementAccrualReducer,
  consultantReducer,
  consultantEngagementReducer,
});

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  });
}

export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = AppStore['dispatch'];
