/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import './Periods.css';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import Modal from 'react-modal'; // todo: ak - encapsulate it with own modal class
import DatePicker from 'react-datepicker';
import Table from '../Table/Table';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import {
  dataBankSelector,
  getConsultantByEmail,
  updateConsultant,
  closePeriod,
  createPeriod,
} from '../../redux/slices/dataBankSlice';
import { Period } from '../../types/Period';
import HelperText from '../HelperText/HelperText';
import { Statuses } from '../../types/Statuses';
import Dropdown from '../Dropdown/Dropdown';
import { selectIsLoggedUserConsultant, selectIsLoggedUserEmailInDb } from '../../redux/slices/dataBankSelector';
import AppButton, { ButtonStyles } from '../AppButton/AppButton';
import AuthenticationService from '../../services/authentication.service';
import { AppRoutes } from '../../routing/appRoutes';
import ConsultantFileUpload from '../FileUpload/ConsultantFileUpload';
import EngagementFileUpload from '../FileUpload/EngagementFileUpload';

const modalStyle: Modal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
  },
  content: {
    bottom: 'auto',
    position: 'absolute',
    left: '0',
    right: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '245px',
    width: '80%',
    maxWidth: '1000px',
    maxHeight: '90%',
    border: '1px solid #ccc',
    boxShadow: '2px 2px 3px #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
  },
};

export default function Periods() {
  const authenticationService = new AuthenticationService();
  const navigate: NavigateFunction = useNavigate();
  const ssoEmail = authenticationService.getLoggedUserEmail();
  const dispatch = useAppDispatch();
  const { periods, loading, error } = useAppSelector(dataBankSelector);
  const availableStatusList: Statuses[] = [Statuses.OPEN, Statuses.CLOSED];
  const isLoggedUserEmailInDb: boolean = useAppSelector(selectIsLoggedUserEmailInDb);
  if (!isLoggedUserEmailInDb) {
    navigate(AppRoutes.LOGGED_USER_EMAIL_NOT_FOUND_ERROR_PAGE);
  }
  const isLoggedUserConsultant: boolean = useAppSelector(selectIsLoggedUserConsultant);
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false);
  const [editedPeriodId, setEditedPeriodId] = React.useState('');

  const [isNewPeriodModalOpen, setIsNewPeriodModalOpen] = React.useState(false);
  const [newPeriodStartDate, setNewPeriodStartDate] = React.useState<Date | null>(null);
  const [newPeriodDeadline, setNewPeriodDeadline] = React.useState<Date | null>(null);
  const [newPeriodHoursAvailable, setNewPeriodHoursAvailable] = React.useState<number | undefined>(undefined);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showEngagementUploadModal, setShowEngagementUploadModal] = useState(false);

  const handleEngagementUploadClick = () => {
    setShowEngagementUploadModal(!showEngagementUploadModal);
  };

  Modal.setAppElement('body');

  useEffect(() => {
    dispatch(getConsultantByEmail(ssoEmail));
  }, []);

  function handleClick(period_id: string, period_name: string): void {
    dispatch(updateConsultant({ period_id, period_name }));
  }

  const handleUploadClick = () => {
    setShowUploadModal(!showUploadModal);
  };

  const onChangePeriodStatusHandler = (periodId: string) => (e: React.ChangeEvent<HTMLElement>):
  void => {
    const newValue: string = (e.target as HTMLInputElement).value;
    if (newValue === Statuses.CLOSED) {
      setIsWarningModalOpen(true);
      setEditedPeriodId(periodId);
    }
  };

  const handleChangesConfirmation = (): void => {
    setIsWarningModalOpen(false);
    dispatch(closePeriod(editedPeriodId));
    setEditedPeriodId('');
  };

  const handleChangesRejection = (): void => {
    setIsWarningModalOpen(false);
    setEditedPeriodId('');
  };

  const handleNewPeriodClick = (): void => {
    setNewPeriodStartDate(null);
    setNewPeriodDeadline(null);
    setNewPeriodHoursAvailable(undefined);
    setIsNewPeriodModalOpen(true);
  };

  const handleNewPeriodRejection = (): void => {
    setIsNewPeriodModalOpen(false);
  };

  const handleNewPeriodConfirmation = (): void => {
    setIsNewPeriodModalOpen(false);
    dispatch(createPeriod({
      startDate: newPeriodStartDate as Date,
      deadline: newPeriodDeadline as Date,
      hoursAvailable: newPeriodHoursAvailable as number,
    }));
  };

  function handlePeriods() {
    return (
      <>
        <div className="title-bar">
          <span className="title-bar-title">Periods</span>
          <div className="right-side-buttons" style={{ display: 'flex', gap: '10px' }}>
            <AppButton
              buttonStyle={isLoggedUserConsultant ? ButtonStyles.DISABLED : ButtonStyles.PRIMARY}
              dataTestId="periods-add-consultants-test-id"
              disabled={isLoggedUserConsultant}
              onClick={handleUploadClick}
            >
              Upload Consultant Data
            </AppButton>

            <AppButton
              buttonStyle={isLoggedUserConsultant ? ButtonStyles.DISABLED : ButtonStyles.PRIMARY}
              disabled={isLoggedUserConsultant}
              onClick={handleEngagementUploadClick}
            >
              Upload Engagement Data
            </AppButton>

            <AppButton
              buttonStyle={isLoggedUserConsultant ? ButtonStyles.DISABLED : ButtonStyles.PRIMARY}
              dataTestId="periods-open-period-test-id"
              disabled={isLoggedUserConsultant}
              onClick={handleNewPeriodClick}
            >
              Add New Period
            </AppButton>
            <Modal
              isOpen={isNewPeriodModalOpen}
              contentLabel="Add New Period"
              style={modalStyle}
            >
              <div className="modal-container">
                <div className="header-row">
                  <div className="header-title">
                    New period details
                  </div>
                  <div className="header-button">
                    <AppButton
                      buttonStyle={ButtonStyles.TERTIARY}
                      onClick={() => { setIsNewPeriodModalOpen(false); }}
                    >
                      <img src="images/close.png" alt="copy icon" height="12" width="12" />
                    </AppButton>
                  </div>
                </div>
                <div className="modal-text">
                  <div className="form-row">
                    <div className="form-label">Start date</div>
                    <DatePicker
                      showIcon
                      selected={newPeriodStartDate}
                      onChange={setNewPeriodStartDate}
                    />
                  </div>
                  <div className="form-row">
                    <div className="form-label">Accruals deadline</div>
                    <DatePicker
                      showIcon
                      selected={newPeriodDeadline}
                      onChange={setNewPeriodDeadline}
                    />
                  </div>
                  <div className="form-row">
                    <div className="form-label">Hours available</div>
                    <input
                      type="text"
                      value={newPeriodHoursAvailable}
                      size={3}
                      onChange={(e) => {
                        const maskedValue = e.target.value.replace(/\D+/g, '').substring(0, 3);
                        e.target.value = maskedValue;
                        const value = parseInt(maskedValue, 10);
                        setNewPeriodHoursAvailable(Number.isFinite(value) ? value : undefined);
                      }}
                    />
                  </div>
                </div>
                <div className="bottom-row">
                  <div className="right-side-buttons">
                    <div className="padding-right">
                      <AppButton
                        buttonStyle={ButtonStyles.SECONDARY}
                        dataTestId="new-period-modal-cancel-test-id"
                        onClick={handleNewPeriodRejection}
                      >
                        Cancel
                      </AppButton>
                    </div>
                    <AppButton
                      buttonStyle={ButtonStyles.PRIMARY}
                      dataTestId="new-period-modal-save-test-id"
                      disabled={!(newPeriodStartDate && newPeriodHoursAvailable && newPeriodDeadline)}
                      onClick={handleNewPeriodConfirmation}
                    >
                      Save
                    </AppButton>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        {/* Modal for Engagement Upload */}
        {showEngagementUploadModal && (
          <Modal isOpen={showEngagementUploadModal} contentLabel="Upload Engagement Data" style={modalStyle}>
            <EngagementFileUpload />
            <AppButton buttonStyle={ButtonStyles.TERTIARY} onClick={() => setShowEngagementUploadModal(false)}>
              Close
            </AppButton>
          </Modal>
        )}
        {showUploadModal && (
          <Modal
            isOpen={showUploadModal}
            contentLabel="Add Consultants"
            style={modalStyle}
          >
            <div className="modal-container">
              <ConsultantFileUpload />
              <AppButton
                buttonStyle={ButtonStyles.TERTIARY}
                onClick={() => setShowUploadModal(false)}
              >
                Close
              </AppButton>
            </div>
          </Modal>
        )}
        <Table
          id="periods-table"
          headers={['Accrual Period', 'Status', '']}
          dataTestId="periods-table-test-id"
        >
          {periods.map((period: Period) => (
            <tr className={period.status_label === Statuses.OPEN ? 'highlight' : ''} key={uuid()}>
              <td>
                <Link
                  to="/engagements"
                  onClick={() => handleClick(period.period_id, period.period_name)}
                >
                  {period.period_name}
                </Link>
              </td>
              <td>
                <Dropdown
                  id={`period-status-dropdown${period.period_id}`}
                  items={availableStatusList}
                  selectedItem={period.status_label}
                  onChangeHandler={onChangePeriodStatusHandler(period.period_id)}
                  dataTestId="select-project-code-test-id"
                  width="100px"
                  isDisabled={isLoggedUserConsultant || period.status_label === Statuses.CLOSED}
                />
                <Modal
                  isOpen={isWarningModalOpen}
                  contentLabel="Operation confirmation"
                  style={modalStyle}
                >
                  <div className="modal-container">
                    <div className="header-row">
                      <div className="error-title">
                        Confirm operation
                      </div>
                      <div className="header-button">
                        <AppButton
                          buttonStyle={ButtonStyles.TERTIARY}
                          onClick={() => { setIsWarningModalOpen(false); }}
                        >
                          <img src="images/close.png" alt="copy icon" height="12" width="12" />
                        </AppButton>
                      </div>
                    </div>
                    <div className="modal-text">
                      This will close the period. This action cannot be undone.
                      <div>
                        <br />
                        Are you sure?
                      </div>
                    </div>
                    <div className="bottom-row">
                      <div className="right-side-buttons">
                        <div className="padding-right">
                          <AppButton
                            buttonStyle={ButtonStyles.PRIMARY}
                            onClick={() => { handleChangesConfirmation(); }}
                            dataTestId="close-period-modal-btn-test-id"
                          >
                            Yes
                          </AppButton>
                        </div>
                        <AppButton
                          buttonStyle={ButtonStyles.SECONDARY}
                          onClick={() => { handleChangesRejection(); }}
                          dataTestId="cancel-close-period-modal-btn-test-id"
                        >
                          No
                        </AppButton>
                      </div>
                    </div>
                  </div>
                </Modal>
              </td>
              <td />
            </tr>
          ))}
        </Table>
        {periods?.length === 0 && <HelperText loading={loading} error={error} content="periods" />}
      </>
    );
  }

  return (
    <div className="container">
      {handlePeriods()}
    </div>
  );
}
