/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { RootState } from '../store';
import { Engagement } from '../../types/Engagement';
import { getApiGatewayBaseUrl } from '../../services/environment.service';
import { http } from '../../api/http';

const getEngagementDetailsUrl = getApiGatewayBaseUrl() + (process.env.REACT_APP_GET_ENGAGEMENT_DETAILS as string);

export interface EngagementState {
  engagements: Engagement[];
  loading: boolean,
  error: string | undefined,
}

const initialState: EngagementState = {
  engagements: [],
  loading: false,
  error: undefined,
};

export const getEngagementDetails = createAsyncThunk(
  'engagement/getEngagementDetails',
  async (payload: { consultant_id: string, period_id: string, role_name: string }) => {
    const params = new URLSearchParams();
    params.append('consultant_id', payload.consultant_id);
    params.append('period_id', payload.period_id);
    params.append('role_name', payload.role_name);

    const config: InternalAxiosRequestConfig = {
      headers: new AxiosHeaders({
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      }),
    };

    const httpClient = http.initHttp(config);
    const responseData = await httpClient.post(
      getEngagementDetailsUrl,
      params,
    ).then((response: AxiosResponse) => (
      response.data));
    return responseData;
  },
);

export const engagementSlice = createSlice({
  name: 'engagement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEngagementDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEngagementDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.engagements = action.payload;
    });
    builder.addCase(getEngagementDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const engagementSelector = (state: RootState) => state.engagementReducer;
export default engagementSlice.reducer;
