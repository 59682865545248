import { Period } from '../../types/Period';
import { RootState } from '../store';
import { UserRole } from '../../types/UserRole';
import { Statuses } from '../../types/Statuses';

export const selectOpenedPeriodStatus = (state: RootState): Statuses | undefined => {
  const openedPeriodId: string = state.dataBankReducer.consultant.period_id as string;
  const openedPeriod = state.dataBankReducer.periods.find((e: Period) => e.period_id
    === openedPeriodId);

  return openedPeriod?.status_label;
};

export const selectIsLoggedUserConsultant = (state: RootState): boolean => state.dataBankReducer
  .consultant?.role_name === UserRole.CONSULTANT;

export const selectIsLoggedUserEmailInDb = (state: RootState): boolean => state.dataBankReducer
  .consultant !== undefined;
