import React from 'react';
import './TitleRow.css';

interface Props {
  title: string;
}

export default function TitleRow({ title }: Props) {
  return (
    <div className="title-row">
      <span className="title">{title}</span>
    </div>
  );
}
