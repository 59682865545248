import React from 'react';
import './Dashboard.css';
import moment from 'moment';
import { useAppSelector } from '../../../redux/hooks';
import { accrualSelector } from '../../../redux/slices/accrualSlice';
import HelperText from '../../HelperText/HelperText';

export default function Dashboard() {
  const { accrual, loading, error } = useAppSelector(accrualSelector);

  return (
    loading === false && error === undefined ? (
      <>
        <div className="dashboardRow">
          <div className="dashboardItemContainer">
            <span className="dashboardItemHeader">Hiring Manager</span>
            <span className="dashboardItemAccrual">{`${accrual.hiring_manager_first_name} ${accrual.hiring_manager_last_name}`}</span>
          </div>
          <div className="dashboardItemContainer">
            <span className="dashboardItemHeader">Submission Deadline</span>
            <span className="dashboardItemAccrual">{moment(accrual.accrual_deadline).format('MMMM DD, YYYY')}</span>
          </div>
          <div className="dashboardItemContainer">
            <span className="dashboardItemHeader">Billable Days</span>
            <span className="dashboardItemAccrual">{String(accrual.billable_hours / 8)}</span>
          </div>
          <div className="dashboardItemContainer">
            <span className="dashboardItemHeader">Hours Available</span>
            <span className="dashboardItemAccrual">{String(Math.trunc(accrual.billable_hours))}</span>
          </div>
        </div>
        <div className="dashboardRow">
          <div className="dashboardItemContainer">
            <span className="dashboardItemHeader">Consultant</span>
            <span className="dashboardItemConsultant">{`${accrual?.consultant_last_name}, ${accrual?.consultant_first_name}`}</span>
          </div>
          <div className="dashboardItemContainer">
            <span className="dashboardItemHeader">Engagement Name</span>
            <span className="dashboardItemConsultant">{accrual?.engagement_name}</span>
          </div>
        </div>
      </>
    ) : (
      <HelperText loading={loading} error={error} content="accrual" />
    )
  );
}
