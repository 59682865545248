import { AuthenticationResult } from '@azure/msal-browser';
import { loginRequest, msalInstance } from '../authentication/authConfig';

export async function aquireToken() {
  // This will get the cached token if there is one and it's still valid.
  // Otherwise, it will request a new one in the background.

  const token = await msalInstance.acquireTokenSilent(loginRequest).then((value: AuthenticationResult) => value.accessToken);
  return token;
}
