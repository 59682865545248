import React from 'react';
import './Toast.css';

export type ToastProps = {
  message: string,
  type: keyof typeof ToastIcons,
  onDismiss: (id: number | string) => void,
  id: number | string,
};

export const ToastIcons = {
  success: <img src="images/success.png" alt="success" />,
  error: <img src="images/error.png" alt="error" />,
};

function Toast(props: ToastProps) {
  const {
    id = new Date().getTime(), message, type, onDismiss,
  } = props;

  return (
    <div className={`toast toast--${type}`} role="alert" id={`${id}`}>
      <div className="toast-header">
        {type && (<div className="icon icon--thumb">{ToastIcons[type]}</div>)}
        <h3 className="toast-title">
          {type === 'success' ? 'Success' : 'Failed'}
        </h3>
      </div>
      <div className="toast-message">
        {message}
      </div>
      <button className="toast-close-btn" onClick={() => onDismiss(id)} type="button">
        <span className="icon">
          <img src="images/close.png" alt="close toast" />
        </span>
      </button>
    </div>
  );
}

export default Toast;
