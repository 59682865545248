import React, { useEffect } from 'react';
import './EngagementAccruals.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import Table from '../Table/Table';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { dataBankSelector, updateConsultant } from '../../redux/slices/dataBankSlice';
import { engagementAccrualSelector, getEngagementAccruals } from '../../redux/slices/engagementAccrualSlice';
import HelperText from '../HelperText/HelperText';
import { EngagementAccrual } from '../../types/EngagementAccrual';
import TitleRow from '../TitleRow/TitleRow';

export default function EngagementAccruals() {
  const dispatch = useAppDispatch();
  const { consultant } = useAppSelector(dataBankSelector);
  const { engagementAccruals, error, loading } = useAppSelector(engagementAccrualSelector);

  const payload = {
    engagement_id: consultant?.engagement_id as string,
    period_id: consultant?.period_id as string,
  };

  useEffect(() => {
    dispatch(getEngagementAccruals(payload));
  }, []);

  function handleClick(consultant_engagement_period_id: string): void {
    dispatch(updateConsultant({ consultant_engagement_period_id }));
  }

  function handleEngagements() {
    if (engagementAccruals?.length > 0) {
      return (
        <>
          <TitleRow title={`${consultant.period_name} Accruals`} />
          <div className="subtitle-row">
            <div className="subtitle-container">
              <span className="subtitle-header">Engagement Name</span>
              <span className="subtitle-item">{consultant.engagement_name}</span>
            </div>
            <div className="subtitle-container">
              <span className="subtitle-header">Hiring Manager</span>
              <span className="subtitle-item">{`${consultant.hm_last_name}, ${consultant.hm_first_name}`}</span>
            </div>
          </div>
          <Table
            id="engagements-table"
            headers={['Consultant', 'Project', 'Task', 'Hours Qty', 'Last Modified']}
            dataTestId="engagement-accruals-table-test-id"
          >
            {engagementAccruals.map((engagementAccrual: EngagementAccrual) => (
              <tr key={uuid()}>
                <td>
                  <Link
                    to="/accrualEditForm"
                    onClick={() => handleClick(engagementAccrual.consultant_engagement_period_id)}
                  >
                    {`${engagementAccrual.consultant_last_name}, ${engagementAccrual.consultant_first_name}`}
                  </Link>
                </td>
                <td>{engagementAccrual.project_name === null ? 'Not entered' : engagementAccrual.project_name}</td>
                <td>{engagementAccrual.task_name === null ? 'Not entered' : engagementAccrual.task_name}</td>
                <td>{engagementAccrual.hours_qty === null ? 'Not entered' : engagementAccrual.hours_qty.replace(/\.\d+/, '')}</td>
                <td>{engagementAccrual.updated_date === null ? '' : moment(engagementAccrual.updated_date).format('MMMM DD, YYYY')}</td>
              </tr>
            ))}
          </Table>
        </>
      );
    }
    return <HelperText loading={loading} error={error} content="engagement accruals" />;
  }

  return (
    <div className="container">
      {handleEngagements()}
    </div>
  );
}
