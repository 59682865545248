import React, { useContext } from 'react';

const ToastContext = React.createContext<{ showToast:(message:string, type: 'success' | 'error', duration?: number)=>void } | null>(null);

export const useToastContext = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('Error, ToastContext must be used within the scope of a ToastProvider.');
  }
  return context;
};
export default ToastContext;
