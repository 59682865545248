import React, { PropsWithChildren } from 'react';
import './AppButton.css';

export enum ButtonStyles {
  PRIMARY = 'primary-btn',
  SECONDARY = 'secondary-btn',
  TERTIARY = 'tertiary-btn',
  DISABLED = 'disabled-btn',
}

type AppButtonProps = {
  buttonStyle: ButtonStyles;
  onClick?: () => void;
  disabled?: boolean;
  dataTestId?: string;
};

const AppButton = function AppButton({
  children,
  buttonStyle,
  onClick,
  disabled = false,
  dataTestId,
}: PropsWithChildren<AppButtonProps>) {
  return (
    <div className={disabled ? 'disabled-button-wrapper' : ''}>
      <button
        className={`button ${disabled ? ButtonStyles.DISABLED : buttonStyle}`}
        type="submit"
        onClick={() => {
          if (!disabled && onClick) {
            onClick();
          }
        }}
        disabled={disabled}
        data-testid={dataTestId}
      >
        {children}
      </button>
    </div>
  );
};

export default AppButton;
