import React, { useState } from 'react';
import Papa from 'papaparse';
import { useSelector } from 'react-redux';
import { DataGrid, GridColDef } from '@mui/x-data-grid'; // Import Material-UI DataGrid
import { addConsultants, consultantSelector } from '../../redux/slices/consultantSlice';
import { ConsultantCsvRecord } from '../../types/ConsultantCsvRecord';
import { AppDispatch } from '../../redux/store'; // Import the correct dispatch type
import { useAppDispatch } from '../../redux/hooks'; // Assuming you have this hook defined

// Define columns for the DataGrid
const columns: GridColDef[] = [
  { field: 'fullName', headerName: '(Cox) Name', width: 150 },
  { field: 'email', headerName: 'Email', width: 250 },
  { field: 'status', headerName: 'Status', width: 120 },
  { field: 'billRate', headerName: 'Bill Rate', width: 120 },
  { field: 'teamManager', headerName: 'Team Manager', width: 150 },
];

const requiredFields = ['Consultant First Name', 'Consultant Last Name', 'Email', 'Status', 'Bill Rate', 'TM', 'TM Email'];

function ConsultantFileUpload() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const dispatch: AppDispatch = useAppDispatch(); // Use the correctly typed dispatch
  const {
    loading,
    error,
    insertedRecords = [],
    updatedRecords = [],
  } = useSelector(consultantSelector);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const parseCSV = async (file: File): Promise<ConsultantCsvRecord[]> => new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: ({ data }) => {
        const parsedConsultants: ConsultantCsvRecord[] = [];
        const errors: string[] = [];

        data.forEach((row: any, index: number) => {
          // Validate each required field
          requiredFields.forEach((field) => {
            if (!row[field]) {
              errors.push(`Row ${index + 1}: Missing required field '${field}'`);
            }
          });

          if (errors.length === 0) {
            parsedConsultants.push({
              firstName: row['Consultant First Name'],
              lastName: row['Consultant Last Name'],
              fullName: row['Consultant Cox Name'],
              status: row.Status,
              email: row.Email,
              tm: row.TM,
              tmEmail: row['TM Email'],
              billRate: row['Bill Rate'],
            });
          }
        });

        if (errors.length > 0) {
          reject(errors);
        } else {
          resolve(parsedConsultants);
        }
      },
      error: reject,
    });
  });

  const handleFileUpload = async () => {
    if (!selectedFile) {
      return;
    }

    try {
      const parsedConsultants = await parseCSV(selectedFile);
      setValidationErrors([]); // Clear previous errors
      dispatch(addConsultants(parsedConsultants)); // Now dispatch works correctly with proper types
    } catch (errors) {
      setValidationErrors(errors as string[]);
    }
  };

  return (
    <div>
      <h3>Upload Consultant CSV File</h3>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button type="button" onClick={handleFileUpload} disabled={loading}>
        {loading ? 'Uploading...' : 'Upload'}
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Display validation errors */}
      {validationErrors.length > 0 && (
        <div style={{ color: 'red' }}>
          <h4>Validation Errors:</h4>
          <ul>
            {validationErrors.map((err) => (
              <li key={err}>{err}</li>
            ))}
          </ul>
        </div>
      )}

      {/* Display inserted and updated records */}
      <h4>Inserted Records</h4>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={insertedRecords.map((record, index) => ({ id: index, ...record }))} // Map to use `id` field
          columns={columns}
          checkboxSelection
        />
      </div>

      <h4>Updated Records</h4>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={updatedRecords.map((record, index) => ({ id: index, ...record }))} // Map to use `id` field
          columns={columns}
          checkboxSelection
        />
      </div>
    </div>
  );
}

export default ConsultantFileUpload;
