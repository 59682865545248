import React, { useEffect } from 'react';
import Modal, { Styles } from 'react-modal';
import './AppBar.css';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import AppButton, { ButtonStyles } from '../AppButton/AppButton';
import { useToastContext } from '../Toast/ToastContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { AccrualDetails } from '../../types/AccrualDetails';
import { dataBankSelector } from '../../redux/slices/dataBankSlice';
import { resetAccrualEditFormState, saveAccrualDetails } from '../../redux/slices/accrualEditFormSlice';
import { getCancelEditAccuralFormRoute, getSaveEditAccuralFormRoute } from '../../services/routing.service';
import { isAnyAccrualDetailsInputFieldEmpty } from '../../services/accrual.service';
import { Consultant } from '../../types/Consultant';
import HoursExceeded from '../HoursExceeded/HoursExceeded';

interface AppBarProps {
  isDisabled?: boolean;
  totalBillableHours?: number;
}

const modalStyles: Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
  },
  content: {
    position: 'absolute',
    left: '0',
    right: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '265px',
    width: '550px',
    border: '1px solid #ccc',
    boxShadow: '2px 2px 3px #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
  },
};

const formatSuccessSave = (savedModel: string) => `${savedModel} have been successfully saved.`;
const formatFailedSave = (savedModel: string) => `${savedModel} failed to save. Please try again.`;
const emptyFormMessage: string = 'One or more data fields do not have a valid value. Please correct it and try to save again.';
const duplicatedProjectTaskCodeMessage: string = 'Data cannot be saved as there is a duplicate \'Project Code\' / \'Task Code\' combination. Please correct it and try to save again.';
const warningTitle: string = 'Warning';
const operationNotAllowedTitle: string = 'Operation not allowed';

function istotalHoursExceded(totalBillableHours: number, hoursAvailable: number): boolean {
  return totalBillableHours > hoursAvailable;
}

function isAnyAccuralEditRowDuplicated(accrualsDetailsRowList: AccrualDetails[]): boolean {
  const projectCodeTaskCodeSet = new Set();
  accrualsDetailsRowList.forEach((e: AccrualDetails) => {
    const key = e.project_name + e.task_name + e.status_id;
    if (projectCodeTaskCodeSet.has(key)) {
      return;
    }
    projectCodeTaskCodeSet.add(key);
  });

  return projectCodeTaskCodeSet.size < accrualsDetailsRowList.length;
}

export default function AppBar({
  isDisabled = false,
  totalBillableHours = 0,
} : AppBarProps) {
  const dispatch = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();
  const { accrual, accrualDetails } = useAppSelector((state) => state.accrualReducer);
  const { error, success } = useAppSelector((state) => state.accrualEditFormReducer);
  const { consultant }: { consultant: Consultant } = useAppSelector(dataBankSelector);
  const accrualsDetailsList: AccrualDetails[] = accrualDetails;
  const hoursAvailable: number = accrual.billable_hours;
  const { showToast } = useToastContext();
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false);
  const [warningModalMessage, setWarningModalMessage] = React.useState('');
  const [warningModalSubMessage, setWarningModalSubMessage] = React.useState('');
  const [modalTitle, setModalTitle] = React.useState('');
  const [isHoursQuantityExceeded, setIsHoursQuantityExceeded] = React.useState(false);
  const closeWarningModal: () => void = () => {
    setIsWarningModalOpen(false);
    setWarningModalMessage('');
    setWarningModalSubMessage('');
    setModalTitle('');
    setIsHoursQuantityExceeded(false);
    if (modalStyles.content) {
      modalStyles.content.height = '265px';
    }
  };
  let isFormInvalid = false;

  Modal.setAppElement('body');

  const handleCancelClick: () => void = () => {
    dispatch(resetAccrualEditFormState());
    navigate(getCancelEditAccuralFormRoute(consultant));
  };

  const prepareModalWarningForDisplay: (modalHeader: string, modalMessage?: string, modalSubMessage?: string) => void = (modalHeader: string, modalMessage?: string, modalSubMessage?: string) => {
    isFormInvalid = true;
    setIsWarningModalOpen(true);
    setModalTitle(modalHeader);
    if (modalMessage) {
      setWarningModalMessage(modalMessage);
    }
    if (modalSubMessage) {
      setWarningModalSubMessage(modalSubMessage);
    }
  };

  const saveForecast: () => void = () => {
    const payload = {
      accrual_id: accrual.accrual_id,
      consultant_id: consultant?.consultant_id as string,
      accrual_details: accrualsDetailsList,
    };
    dispatch(saveAccrualDetails(payload));
  };

  const saveForecastAndCloseModal: () => void = () => {
    closeWarningModal();
    saveForecast();
  };

  const handleSaveAccruals: () => void = () => {
    setIsHoursQuantityExceeded(false);
    if (isAnyAccrualDetailsInputFieldEmpty(accrualsDetailsList)) {
      prepareModalWarningForDisplay(operationNotAllowedTitle, emptyFormMessage, undefined);
    } else if (istotalHoursExceded(totalBillableHours, hoursAvailable)) {
      prepareModalWarningForDisplay(warningTitle, '');
      setIsHoursQuantityExceeded(true);
      if (modalStyles.content) {
        modalStyles.content.height = '350px';
      }
    } else if (isAnyAccuralEditRowDuplicated(accrualsDetailsList)) {
      prepareModalWarningForDisplay(operationNotAllowedTitle, duplicatedProjectTaskCodeMessage);
    }
    if (isFormInvalid) {
      return;
    }
    saveForecast();
  };

  const handleToast = () => {
    if (success) {
      showToast(formatSuccessSave('Accrual hours'), 'success');
      navigate(getSaveEditAccuralFormRoute(consultant));
    }
    if (error) {
      showToast(formatFailedSave('Accrual hours'), 'error');
    }
  };

  useEffect(() => {
    handleToast();
    dispatch(resetAccrualEditFormState());
  }, [success, error]);

  return (
    <div className="App-bar" data-testid="appBar-test-id">
      <div className="title-bar">
        <span className="title-bar-title">{`${accrual.period_name} Accruals Estimation`}</span>
        <AppButton
          buttonStyle={ButtonStyles.TERTIARY}
          onClick={() => handleCancelClick()}
          dataTestId="appBar-cancel-btn-test-id"
        >
          Cancel
        </AppButton>
        <AppButton
          buttonStyle={isDisabled ? ButtonStyles.DISABLED : ButtonStyles.SECONDARY}
          onClick={() => handleSaveAccruals()}
          dataTestId="appBar-save-btn-test-id"
          disabled={isDisabled}
        >
          Save
        </AppButton>
        <Modal
          isOpen={isWarningModalOpen}
          contentLabel="Save error modal"
          style={modalStyles}
        >
          <div className="modal-container">
            <div className="header-row">
              <div className="error-title">
                {modalTitle}
              </div>
              <div className="header-button">
                <AppButton buttonStyle={ButtonStyles.TERTIARY} onClick={closeWarningModal}>
                  <img src="images/close.png" alt="copy icon" height="12" width="12" />
                </AppButton>
              </div>
            </div>
            <div className="modal-text">
              {isHoursQuantityExceeded
                && (
                  <HoursExceeded />
                )}
              {!isHoursQuantityExceeded
                && (
                  <div>
                    {warningModalMessage}
                    {warningModalSubMessage !== ''
                    && (
                      <div>
                        <br />
                        {warningModalSubMessage}
                      </div>
                    )}
                  </div>
                )}
            </div>
            <div className="bottom-row">
              {isHoursQuantityExceeded
                && (
                  <div className="bottom-row">
                    <div className="right-side-buttons">
                      <div className="padding-right">
                        <AppButton
                          buttonStyle={ButtonStyles.PRIMARY}
                          onClick={saveForecastAndCloseModal}
                          dataTestId="close-period-modal-btn-test-id"
                        >
                          OK
                        </AppButton>
                      </div>
                      <AppButton
                        buttonStyle={ButtonStyles.SECONDARY}
                        onClick={closeWarningModal}
                        dataTestId="cancel-close-period-modal-btn-test-id"
                      >
                        Cancel
                      </AppButton>
                    </div>
                  </div>
                )}
              {!isHoursQuantityExceeded
                && (
                  <div className="right-side-buttons">
                    <AppButton
                      buttonStyle={ButtonStyles.PRIMARY}
                      onClick={closeWarningModal}
                      dataTestId="appBar-close-warning-modal-btn-test-id"
                    >
                      OK
                    </AppButton>
                  </div>
                )}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
