import React from 'react';
import './UserNotFound.css';

const errorMessage: string = 'You do not have the necessary permissions to utilize this application. If you require access, please contact';

export default function UserNotFound() {
  return (
    <div className="user-not-found-error-page" data-testid="user-not-found-error-page-id">
      <p>
        {errorMessage}
        <a href="mailto:timecast@daugherty.com">our Timecast support team</a>
        .
      </p>
    </div>
  );
}
