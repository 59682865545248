import React, { useEffect, useState } from 'react';
import './AccrualEditForm.css';
import { v4 as uuid } from 'uuid';
import Dashboard from './Dashboard/Dashboard';
import AppButton, { ButtonStyles } from '../AppButton/AppButton';
import AccrualDetailsRow from './AccrualDetailsRow/AccrualDetailsRow';
import AppBar from '../AppBar/AppBar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getAccrual, setAccrualDetailsList } from '../../redux/slices/accrualSlice';
import getStatusID from '../../services/status.service';
import { AccrualDetails } from '../../types/AccrualDetails';
import HelperText from '../HelperText/HelperText';
import { isAnyAccrualDetailsInputFieldEmpty } from '../../services/accrual.service';
import { selectOpenedPeriodStatus, selectIsLoggedUserConsultant } from '../../redux/slices/dataBankSelector';
import { Statuses } from '../../types/Statuses';

function isFormDisabled(): boolean {
  const openedPeriodStatus: Statuses | undefined = useAppSelector(selectOpenedPeriodStatus);
  const isOpenedPeriodInClosedOrSubmittedStatus = openedPeriodStatus
    ? [Statuses.CLOSED, Statuses.SUBMITTED].includes(openedPeriodStatus) : false;
  const isLoggedUserConsultant: boolean = useAppSelector(selectIsLoggedUserConsultant);
  const isFormReadonly = isLoggedUserConsultant && isOpenedPeriodInClosedOrSubmittedStatus;

  return isFormReadonly;
}

export default function AccrualEditForm() {
  const { error, loading } = useAppSelector((state) => state.accrualReducer);
  const accrualDetailsList = useAppSelector((state) => state.accrualReducer.accrualDetails);
  const [disableAddRowButton, setDisableAddRowButton] = useState<boolean>(false);
  const isFormReadonly: boolean = isFormDisabled();
  const { consultant, statuses } = useAppSelector((state) => state.dataBankReducer);
  const consultantEngagementPeriodId = consultant.consultant_engagement_period_id;
  const dispatch = useAppDispatch();
  const removedStatus: string = getStatusID(statuses, 'accrual_details', 'removed');
  const activeStatus: string = getStatusID(statuses, 'accrual_details', 'active');
  const totalBillableHours: number = accrualDetailsList.filter((item) => item.status_id !== removedStatus)
    .reduce((sum: number, current: AccrualDetails) => sum + Math.trunc(Number(current.hours_qty))
       || 0, 0);

  useEffect(() => {
    dispatch(getAccrual(consultantEngagementPeriodId as string));
  }, []);

  const handleAddAdditonalRowButton = () => {
    if (isAnyAccrualDetailsInputFieldEmpty(accrualDetailsList)) {
      return setDisableAddRowButton(true);
    }
    return setDisableAddRowButton(false);
  };

  const addAccrualDetailsRow = (): void => {
    const newAccrualDetail: AccrualDetails = {
      id: uuid(),
      accrual_details_id: '',
      project_name: '',
      task_name: '',
      project_task_id: '',
      hours_qty: '',
      status_id: activeStatus,
    };
    dispatch(setAccrualDetailsList([...accrualDetailsList, newAccrualDetail]));
  };

  useEffect(() => {
    handleAddAdditonalRowButton();
    if (accrualDetailsList.length === 0) {
      addAccrualDetailsRow();
    }
  }, [accrualDetailsList]);

  const updateAccrualDetailsRow = (
    id: string,
    accrual_details_id: string,
    project_name: string,
    task_name: string,
    project_task_id: string,
    hours_qty: string,
    status_id: string,
  ): void => {
    const accrualDetailsRowList: AccrualDetails[] = accrualDetailsList.map((item) => {
      if ((item.id !== undefined && item.id === id) || (item.accrual_details_id !== '' && item.accrual_details_id === accrual_details_id)) {
        return {
          id,
          accrual_details_id,
          project_name,
          task_name,
          project_task_id,
          hours_qty,
          status_id,
        };
      }
      return item;
    });
    dispatch(setAccrualDetailsList(accrualDetailsRowList));
  };

  const deleteAccrualDetailsRow = (id: string, accrual_details_id: string): void => {
    if (accrual_details_id === '') {
      const filteredAccrualDetails: AccrualDetails[] = accrualDetailsList.filter((item) => item.id !== id);
      dispatch(setAccrualDetailsList(filteredAccrualDetails));
    }
    if (id === undefined) {
      const accrualDetailsRowList: AccrualDetails[] = accrualDetailsList.map((item) => {
        if (item.accrual_details_id === accrual_details_id) {
          return {
            id: item.id,
            accrual_details_id: item.accrual_details_id,
            project_name: item.project_name,
            task_name: item.task_name,
            project_task_id: item.project_task_id,
            hours_qty: item.hours_qty,
            status_id: removedStatus,
          };
        }
        return item;
      });
      dispatch(setAccrualDetailsList(accrualDetailsRowList));
    }
  };

  const handleAccrualEditForm = () => {
    if (loading === false && error === undefined) {
      return (
        <>
          <AppBar isDisabled={isFormReadonly} totalBillableHours={totalBillableHours} />
          <div className="formContainer">
            <Dashboard />
            <div className="formSection projectTaskSection">
              {accrualDetailsList?.map((accrualDetailsRow: AccrualDetails) => (
                accrualDetailsRow.status_id === activeStatus
                && (
                  <div className="formRow" key={`${accrualDetailsRow.id}${accrualDetailsRow.accrual_details_id}`}>
                    <AccrualDetailsRow
                      isDisabled={isFormReadonly}
                      accrualDetailsRow={accrualDetailsRow}
                      updateAccrualDetailsRow={updateAccrualDetailsRow}
                      deleteAccrualDetailsRow={deleteAccrualDetailsRow}
                    />
                  </div>
                )
              ))}
              <div className="formRow">
                <AppButton
                  buttonStyle={ButtonStyles.SECONDARY}
                  onClick={() => {
                    addAccrualDetailsRow();
                  }}
                  dataTestId="accrual-add-btn-test-id"
                  disabled={isFormReadonly || disableAddRowButton}
                >
                  <img
                    src="images/plus.png"
                    alt="plus icon"
                    height="14"
                    width="14"
                  />
                  Add Additional Row
                </AppButton>
              </div>
            </div>
            <div className="footerSection">
              <span>Total Hours</span>
              <span>{ totalBillableHours }</span>
            </div>
          </div>
        </>
      );
    }
    return <HelperText loading={loading} error={error} content="accrual form" />;
  };

  return (
    <div>
      {handleAccrualEditForm()}
    </div>
  );
}
