import React, { ChangeEvent } from 'react';
import './Textfield.css';

interface Props {
  id: string;
  label: string;
  value?: string;
  placeHolder?: string;
  disabled?: boolean;
  isError?: boolean;
  errorMessage?: string;
  onChangeHandler?: (e: ChangeEvent<HTMLInputElement>)=>void;
  dataTestId?: string;
}

export default function Textfield({
  id, label, value, placeHolder, disabled, onChangeHandler, isError, errorMessage, dataTestId,
}: Props) {
  return (
    <div id="textfieldContainer">
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        className={(isError) ? 'error' : undefined}
        type="text"
        value={value}
        placeholder={placeHolder}
        disabled={disabled}
        onChange={(event) => {
          if (!disabled && onChangeHandler) {
            onChangeHandler(event);
          }
        }}
        data-testid={dataTestId}
      />
      {errorMessage && <span className="errorMessage">{errorMessage}</span>}
    </div>
  );
}
