import { Status } from '../types/Status';

// This function was created to retrieve status Id's for any period, engagement, accrual, etc.
// However, Redux's useSelector hook cannot be called outside of a component.
// Therefore, to use this function you retrieve the array of status objects from the databankSlice,
// and pass it in as an argument from the component when you are calling this function.

const getStatusID = (statusArray: Status[], tableName: string, statusLabel: string) => {
  const activeStatus = statusArray.find(
    (item) => item.table_name === tableName && item.status_label === statusLabel,
  );
  return activeStatus ? activeStatus.status_id : '';
};

export default getStatusID;
