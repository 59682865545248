import React, { useState } from 'react';
import Papa from 'papaparse';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addEngagements, engagementSelector } from '../../redux/slices/consultantEngagementSlice';
import { EngagementCsvRecord } from '../../types/EngagementCsvRecord';

function EngagementFileUpload() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const { engagements, loading, error } = useAppSelector(engagementSelector);

  const requiredFields = [
    'Engagement Name',
    'Engagement Number',
    'Engagement Status',
    'Consultant Email',
    'Consultant Engagement Status',
    'Billing Type',
    'Hiring Manager Email',
    'Hiring Manager Name',
    'Start Date',
    'End Date',
  ];

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const parseCSV = async (file: File): Promise<EngagementCsvRecord[]> => new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: ({ data }) => {
        const parsedEngagements: EngagementCsvRecord[] = [];
        const errors: string[] = [];

        data.forEach((row: any, index: number) => {
          requiredFields.forEach((field) => {
            if (!row[field]) {
              errors.push(`Row ${index + 1}: Missing required field '${field}'`);
            }
          });

          if (errors.length === 0) {
            parsedEngagements.push({
              engagementName: row['Engagement Name'],
              engagementNumber: row['Engagement Number'],
              engagementStatus: row['Engagement Status'],
              consultantEmail: row['Consultant Email'],
              consultantEngagementStatus: row['Consultant Engagement Status'],
              billingType: row['Billing Type'],
              hiringManagerEmail: row['Hiring Manager Email'],
              hiringManagerName: row['Hiring Manager Name'],
              startDate: row['Start Date'],
              endDate: row['End Date'],
            });
          }
        });

        if (errors.length > 0) {
          reject(errors);
        } else {
          resolve(parsedEngagements);
        }
      },
      error: reject,
    });
  });

  const handleFileUpload = async () => {
    if (!selectedFile) {
      return;
    }

    try {
      const parsedEngagements = await parseCSV(selectedFile);
      setValidationErrors([]);
      dispatch(addEngagements(parsedEngagements));
    } catch (errors) {
      setValidationErrors(errors as string[]);
    }
  };

  const columns: GridColDef[] = [
    { field: 'engagementNumber', headerName: 'Engagement Number', width: 150 },
    { field: 'engagementName', headerName: 'Engagement Name', width: 200 },
    { field: 'consultantEmail', headerName: 'Consultant Email', width: 200 },
    { field: 'consultantEngagementStatus', headerName: 'Status', width: 150 },
    { field: 'startDate', headerName: 'Start Date', width: 150 },
    { field: 'endDate', headerName: 'End Date', width: 150 },
    { field: 'operation', headerName: 'Operation', width: 150 },
  ];

  return (
    <div>
      <h3>Upload Engagement CSV File</h3>
      <input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
      />
      <button
        type="button"
        onClick={handleFileUpload}
        disabled={loading}
      >
        {loading ? 'Uploading...' : 'Upload Engagement Data'}
      </button>

      {validationErrors.length > 0 && (
        <div style={{ color: 'red' }}>
          <h4>Validation Errors:</h4>
          <ul>
            {validationErrors.map((errorMessage) => (
              <li key={errorMessage}>
                {errorMessage}
              </li>
            ))}
          </ul>
        </div>
      )}

      {error && (
        <p style={{ color: 'red' }}>
          Error:
          <br />
          {error}
        </p>
      )}

      {engagements.length > 0 && (
        <>
          <h4>Uploaded Engagements</h4>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={engagements}
              columns={columns}
              getRowId={(row) => row.engagementNumber}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default EngagementFileUpload;
