/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { RootState } from '../store';
import { Accrual } from '../../types/Accrual';
import { AccrualDetails } from '../../types/AccrualDetails';
import { ProjectTaskOptions } from '../../types/ProjectTaskOptions';
import { getApiGatewayBaseUrl } from '../../services/environment.service';
import { http } from '../../api/http';

const getAccrualUrl = getApiGatewayBaseUrl() + (process.env.REACT_APP_GET_ACCRUAL as string);

export interface AccrualState {
  accrual: Accrual,
  accrualDetails: AccrualDetails[],
  projectTaskOptions: ProjectTaskOptions[],
  loading: boolean,
  error: string | undefined,
}

const initialState: AccrualState = {
  accrual: {} as Accrual,
  accrualDetails: [],
  projectTaskOptions: [],
  loading: false,
  error: undefined,
};

export const getAccrual = createAsyncThunk(
  'accrual/getAccrual',
  async (consultant_engagement_period_id: string) => {
    const params = new URLSearchParams();
    params.append('consultant_engagement_period_id', consultant_engagement_period_id);

    const config: InternalAxiosRequestConfig = {
      headers: new AxiosHeaders({
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      }),
    };

    const httpClient = http.initHttp(config);
    const responseData = await httpClient.post(
      getAccrualUrl,
      params,
    ).then((response: AxiosResponse) => (
      response.data));
    return responseData;
  },
);

export const accrualSlice = createSlice({
  name: 'accrual',
  initialState,
  reducers: {
    setAccrualDetailsList: (state, action: PayloadAction<AccrualDetails[]>) => ({
      ...state,
      accrualDetails: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getAccrual.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAccrual.fulfilled, (state, action) => {
      const accrualObject: Accrual = action.payload[0][1][0];
      const accrualDetailsArray: AccrualDetails[] = action.payload[1][1];
      const projectTaskOptionsArray: ProjectTaskOptions[] = action.payload[2][1];
      state.loading = false;
      state.accrual = accrualObject;
      state.accrualDetails = accrualDetailsArray;
      state.projectTaskOptions = projectTaskOptionsArray;
    });
    builder.addCase(getAccrual.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const accrualSelector = (state: RootState) => state.accrualReducer;
export const { setAccrualDetailsList } = accrualSlice.actions;
export default accrualSlice.reducer;
