import { useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginRequest } from './authConfig';

function LoginRedirect() {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      if (isAuthenticated) {
        const activeAccount = instance.getActiveAccount();
        if (!activeAccount && instance.getAllAccounts().length > 0) {
          const accounts = instance.getAllAccounts();
          instance.setActiveAccount(accounts[0]);
        }

        const next = sessionStorage.getItem('nextUrl');
        if (next != null) {
          sessionStorage.removeItem('nextUrl');
          navigate(next);
        }
      } else {
        const handleSignIn = async () => {
          sessionStorage.setItem('nextUrl', location.pathname);
          await instance.loginRedirect(loginRequest)
            .catch((e) => {
              console.error(e); // eslint-disable-line no-console
            });
        };
        handleSignIn();
      }
    }
  }, [instance, inProgress, isAuthenticated]);

  return null;
}

export default LoginRedirect;
