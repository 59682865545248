import React, { useEffect, useRef } from 'react';
import Toast, { ToastProps } from './Toast';
import './ToastList.css';

export const TOAST_POSITIONS = {
  'top-right': 'Top-right',
  'top-left': 'Top-left',
  'bottom-right': 'Bottom-right',
  'bottom-left': 'Bottom-left',
};

export type ToastListProps = {
  data: Omit<ToastProps, 'onDismiss'>[],
  position: keyof typeof TOAST_POSITIONS,
  onRemoveListItem: (id: any) => void,
};
function ToastList(props: ToastListProps) {
  const { data, position, onRemoveListItem } = props;
  const listRef = useRef(null);
  const handleScrolling = (element:any) => {
    const isTopPosition = position.startsWith('Top');
    element?.scrollTo(0, isTopPosition ? element.scrollHeight : 0);
  };

  useEffect(() => {
    handleScrolling(listRef.current);
  }, [position, data]);

  const sortedData = position.startsWith('Bottom') ? [...data].reverse() : [...data];
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!sortedData.length) return <></>;
  return (
    <div
      className={`toast-list toast-list--${TOAST_POSITIONS[position]}`}
      aria-live="assertive"
      ref={listRef}
    >
      {sortedData.map((toast) => (
        <Toast
          key={toast.id}
          id={toast.id}
          message={toast.message}
          type={toast.type}
          onDismiss={() => onRemoveListItem(toast.id)}
        />
      ))}
    </div>
  );
}
export default ToastList;
