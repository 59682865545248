import React from 'react';
import './HelperText.css';

type HelperTextProps = {
  error: string | undefined;
  loading: boolean;
  content: string;
};

export default function HelperText({ error, loading, content }: HelperTextProps) {
  if (loading) {
    return <p className="helper-text">Loading...</p>;
  }
  if (error) {
    return <p className="helper-text">{`Error loading ${content}. Try again.`}</p>;
  }
  return <p className="helper-text">{`No ${content} found.`}</p>;
}
