import React, { ChangeEvent } from 'react';
import { v4 as uuid } from 'uuid';

import './Dropdown.css';

interface Props {
  id: string;
  label?: string;
  items: string[];
  selectedItem: string;
  isError?: boolean;
  errorMessage?: string;
  onChangeHandler?: (e: ChangeEvent<HTMLSelectElement>) => void;
  width?: string;
  dataTestId?: string;
  isDisabled?: boolean;
}

export default function Dropdown({
  id,
  label,
  items,
  selectedItem,
  isError,
  errorMessage,
  onChangeHandler,
  width,
  dataTestId,
  isDisabled,
}: Props) {
  return (
    <div id="dropdownContainer" style={{ maxWidth: width }}>
      <label htmlFor={id}>{label}</label>
      <select
        name={id}
        id={id}
        value={selectedItem}
        onChange={onChangeHandler}
        className={isError ? 'error' : undefined}
        data-testid={dataTestId}
        disabled={isDisabled}
      >
        {label && (
          <option value="" disabled>{`----- Select a ${label} -----`}</option>
        )}
        {items.map((item) => (
          <option value={item} key={uuid()}>
            {item}
          </option>
        ))}
      </select>
      {errorMessage && <span className="errorMessage">{errorMessage}</span>}
    </div>
  );
}
