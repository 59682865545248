/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { AccrualDetails } from '../../types/AccrualDetails';
import { getApiGatewayBaseUrl } from '../../services/environment.service';
import { http } from '../../api/http';

const saveAccrualDetailsUrl = getApiGatewayBaseUrl() + (process.env.REACT_APP_SAVE_ACCRUAL_DETAILS as string);

interface AccrualEditFormState {
  accrualDetailsRowList: AccrualDetails[];
  loading: boolean,
  success: boolean,
  error: string | undefined,
}

const initialState: AccrualEditFormState = {
  accrualDetailsRowList: [],
  loading: false,
  success: false,
  error: undefined,
};

export const saveAccrualDetails = createAsyncThunk(
  'accrualDetails/saveAccrualDetails',
  async (payload: { accrual_id: string, consultant_id: string, accrual_details: AccrualDetails[] }) => {
    const params = new URLSearchParams();
    params.append('accrual_id', payload.accrual_id);
    params.append('consultant_id', payload.consultant_id);
    params.append('accrual_details', JSON.stringify(payload.accrual_details));

    const config: InternalAxiosRequestConfig = {
      headers: new AxiosHeaders({
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      }),
    };

    const httpClient = http.initHttp(config);
    const responseData = await httpClient.post(
      saveAccrualDetailsUrl,
      params,
    ).then((response: AxiosResponse) => (
      response.data));
    return responseData;
  },
);

const accrualEditFormSlice = createSlice({
  name: 'accrualEditForm',
  initialState,
  reducers: {
    setAccrualDetailsRowList: (state, action: PayloadAction<AccrualDetails[]>) => ({
      ...state,
      accrualDetailsRowList: action.payload,
    }),
    resetAccrualEditFormState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(saveAccrualDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveAccrualDetails.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(saveAccrualDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { setAccrualDetailsRowList, resetAccrualEditFormState } = accrualEditFormSlice.actions;
export default accrualEditFormSlice.reducer;
