import React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import AccrualEditForm from './components/AccrualEditForm/AccrualEditForm';
import Periods from './components/Periods/Periods';
import Engagements from './components/Engagements/Engagements';
import EngagementAccruals from './components/EngagementAccruals/EngagementAccruals';
import { AppRoutes } from './routing/appRoutes';
import LoginRedirect from './authentication/redirect';
import UserNotFound from './components/UserNotFound/UserNotFound';

function App() {
  return (
    <Router>
      <LoginRedirect />
      <div className="app">
        <Header />
        <AuthenticatedTemplate>
          <Routes>
            <Route path="/" element={<Periods />} />
            <Route path={AppRoutes.ENGAGEMNETS} element={<Engagements />} />
            <Route path={AppRoutes.ENGAGEMNET_ACCURALS} element={<EngagementAccruals />} />
            <Route path={AppRoutes.ACCURAL_EDIT_FORM} element={<AccrualEditForm />} />
            <Route path={AppRoutes.LOGGED_USER_EMAIL_NOT_FOUND_ERROR_PAGE} element={<UserNotFound />} />
          </Routes>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          Please sign in
        </UnauthenticatedTemplate>
      </div>
    </Router>
  );
}

export default App;
